/* First item (index 0) of each subarray is total number of chapters. Subsequent numbers are the total verses per chapter, i.e. index (1) is the verses in chapter 1 for that book */
let vsArray  = [
  /*0: No book selected*/
  [0],
  /*1: Genesis*/
  [50,31,25,24,26,32,22,24,22,29,32,32,20,18,24,21,16,27,33,38,18,34,24,20,67,34,35,46,22,35,43,55,32,20,31,29,43,36,30,23,23,57,38,34,34,28,34,31,22,33,26],
  /*2: Exodus*/
  [40,22,25,22,31,23,30,25,32,35,29,10,51,22,31,27,36,16,27,25,26,36,31,33,18,40,37,21,43,46,38,18,35,23,35,35,38,29,31,43,38],
  /*3: Leviticus*/
  [27,17,16,17,35,19,30,38,36,24,20,47,8,59,57,33,34,16,30,37,27,24,33,44,23,55,46,34],
  /*4: Numbers*/
  [36,54,34,51,49,31,27,89,26,23,36,35,16,33,45,41,50,13,32,22,29,35,41,30,25,18,65,23,31,40,16,54,42,56,29,34,13],
  /*5: Deuteronomy*/
  [34,46,37,29,49,33,25,26,20,29,22,32,32,18,29,23,22,20,22,21,20,23,30,25,22,19,19,26,68,29,20,30,52,29,12],
  /*6: Joshua*/
  [24,18,24,17,24,15,27,26,35,27,43,23,24,33,15,63,10,18,28,51,9,45,34,16,33],
  /*Judges*/
  [21,36,23,31,24,31,40,25,35,57,18,40,15,25,20,20,31,13,31,30,48,25],
  /*Ruth*/
  [4,22,23,18,22],
  /*1 Samuel */
  [31,28,36,21,22,12,21,17,22,27,27,15,25,23,52,35,23,58,30,24,42,15,23,29,22,44,25,12,25,11,31,13],
  /*2 Samuel*/
  [24,27,32,39,12,25,23,29,18,13,19,27,31,39,33,37,23,29,33,43,26,22,51,39,25],
  /*1 Kings*/
  [22,53,46,28,34,18,38,51,66,28,29,43,33,34,31,34,34,24,46,21,43,29,53],
  /*2 Kings*/
  [25,18,25,27,44,27,33,20,29,37,36,21,21,25,29,38,20,41,37,37,21,26,20,37,20,30],
  /*1 Chronicles*/
  [29,54,55,24,43,26,81,40,40,44,14,47,40,14,17,29,43,27,18,18,8,30,19,32,31,31,32,34,21,30],
  /*2 Chronicles*/
  [36,17,18,17,22,14,42,22,18,31,19,23,16,22,15,19,14,19,34,11,37,20,12,21,27,28,23,9,27,36,27,21,33,25,33,27,23],
  /*Ezra*/
  [10,11,70,13,24,17,22,28,36,15,44],
  /*Nehemiah*/
  [13,11,20,32,23,19,19,73,18,38,39,36,47,31],
  /*Esther*/
  [10,22,23,15,17,14,14,10,17,32,3],
  /*Job*/
  [42,22,13,26,21,27,30,21,22,35,22,20,25,28,22,35,22,16,21,29,29,34,30,17,25,6,14,23,28,25,31,40,22,33,37,16,33,24,41,30,24,34,17],
  /*Psalms*/
  [150,6,12,8,8,12,10,17,9,20,18,7,8,6,7,5,11,15,50,14,9,13,31,6,10,22,12,14,9,11,12,24,11,22,22,28,12,40,22,13,17,13,11,5,26,17,11,9,14,20,23,19,9,6,7,23,13,11,11,17,12,8,12,11,10,13,20,7,35,36,5,24,20,28,23,10,12,20,72,13,19,16,8,18,12,13,17,7,18,52,17,16,15,5,23,11,13,12,9,9,5,8,28,22,35,45,48,43,13,31,7,10,10,9,8,18,19,2,29,176,7,8,9,4,8,5,6,5,6,8,8,3,18,3,3,21,26,9,8,24,13,10,7,12,15,21,10,20,14,9,6],
  /*Proverbs*/
  [31,33,22,35,27,23,35,27,36,18,32,31,28,25,35,33,33,28,24,29,30,31,29,35,34,28,28,27,28,27,33,31],
  /*Ecclesiastes*/
  [12,18,26,22,16,20,12,29,17,18,20,10,14],
  /*Song of Solomon*/
  [8,17,17,11,16,16,13,13,14],
  /*Isaiah*/
  [66,31,22,26,6,30,13,25,22,21,34,16,6,22,32,9,14,14,7,25,6,17,25,18,23,12,21,13,29,24,33,9,20,24,17,10,22,38,22,8,31,29,25,28,28,25,13,15,22,26,11,23,15,12,17,13,12,21,14,21,22,11,12,19,12,25,24],
  /*Jeremiah*/
  [52,19,37,25,31,31,30,34,22,26,25,23,17,27,22,21,21,27,23,15,18,14,30,40,10,38,24,22,17,32,24,40,44,26,22,19,32,21,28,18,16,18,22,13,30,5,28,7,47,39,46,64,34],
  /*Lamentations*/
  [5,22,22,66,22,22],
  /*Ezekiel*/
  [48,28,10,27,17,17,14,27,18,11,22,25,28,23,23,8,63,24,32,14,49,32,31,49,27,17,21,36,26,21,26,18,32,33,31,15,38,28,23,29,49,26,20,27,31,25,24,23,35],
  /*Daniel*/
  [12,21,49,30,37,31,28,28,27,27,21,45,13],
  /*Hosea*/
  [14,11,23,5,19,15,11,16,14,17,15,12,14,16,9],
  /*Joel*/
  [3,20,32,21],
  /*Amos*/
  [9,15,16,15,13,27,14,17,14,15],
  /*Obadiah*/
  [1,21],
  /*Jonah*/
  [4,17,10,10,11],
  /*Micah*/
  [7,16,13,12,13,15,16,20],
  /*Nahum*/
  [3,15,13,19],
  /*Habakkuk*/
  [3,17,20,19],
  /*Zephaniah*/
  [3,18,15,20],
  /*Haggai*/
  [2,15,23],
  /*Zechariah*/
  [14,21,13,10,14,11,15,14,23,17,12,17,14,9,21],
  /*Malachi*/
  [4,14,17,18,6],
  /*Matthew*/
  [28,25,23,17,25,48,34,29,34,38,42,30,50,58,36,39,28,27,35,30,34,46,46,39,51,46,75,66,20],
  /*Mark*/
  [16,45,28,35,41,43,56,37,38,50,52,33,44,37,72,47,20],
  /*Luke*/
  [24,80,52,38,44,39,49,50,56,62,42,54,59,35,35,32,31,37,43,48,47,38,71,56,53],
  /*John*/
  [21,51,25,36,54,47,71,53,59,41,42,57,50,38,31,27,33,26,40,42,31,25],
  /*Acts*/
  [28,26,47,26,37,42,15,60,40,43,48,30,25,52,28,41,40,34,28,41,38,40,30,35,27,27,32,44,31],
  /*Romans*/
  [16,32,29,31,25,21,23,25,39,33,21,36,21,14,23,33,27],
  /*1 Corinthians*/
  [16,31,16,23,21,13,20,40,13,27,33,34,31,13,40,58,24],
  /*2 Corinthians*/
  [13,24,17,18,18,21,18,16,24,15,18,33,21,14],
  /*Galatians*/
  [6,24,21,29,31,26,18],
  /*Ephesians*/
  [6,23,22,21,32,33,24],
  /*Philippians*/
  [4,30,30,21,23],
  /*Colossians*/
  [4,29,23,25,18],
  /*1 Thessalonians*/
  [5,10,20,13,18,28],
  /*2 Thessalonians*/
  [3,12,17,18],
  /*1 Timothy*/
  [6,20,15,16,16,25,21],
  /*2 Timothy*/
  [4,18,26,17,22],
  /*Titus*/
  [3,16,15,15],
  /*Philemon*/
  [1,25],
  /*Hebrews*/
  [13,14,18,19,16,14,20,28,13,28,39,40,29,25],
  /*James*/
  [5,27,26,18,17,20],
  /*1 Peter*/
  [5,25,25,22,19,14],
  /*2 Peter*/
  [3,21,22,18],
  /*1 John*/
  [5,10,29,24,21,21],
  /*2 John*/
  [1,13],
  /*3 John*/
  [1,14],
  /*Jude*/
  [1,25],
  /*Revelation*/
  [22,20,29,22,11,14,17,17,13,21,11,19,17,18,20,8,21,18,24,21,15,27,21]
];

/* Has to run once on load, we may have come back from the results page, and some scripture values were passed in */
window.addEventListener("load", changeCh);
/* If book is changed, get the chapters. If chapter is changed, get the verses */
document.getElementById("adv_search_bk").addEventListener("change", changeCh);
document.getElementById("adv_search_ch").addEventListener("change", changeVs);

function changeCh() {
  let b = document.getElementById('adv_search_bk').value;
  let selc = document.getElementById('adv_search_ch');
  let c = selc.value;
  selc.options.length = 0;
  if (b == 0) {
    // No book selected, so default option
    selc.add(new Option("Chapter", 0));
    selc[0].disabled = true;
  } else {
    let frag = document.createDocumentFragment(), elOption;
    let chs = vsArray[b][0];
    elOption = frag.appendChild(new Option('', 0));
    for (let i = 1; i <= chs; i++) {
      elOption = frag.appendChild(new Option(i));
    }
    selc.appendChild(frag);
    selc.value = c;
  }
  changeVs();
}

function changeVs() {
  let b = document.getElementById('adv_search_bk').value;
  let c = document.getElementById('adv_search_ch').value;
  let selv = document.getElementById('adv_search_vs');
  let v = selv.value;
  selv.options.length = 0;
  if (b == 0) {
    // No book selected, so default option
    selv.add(new Option("Verse", 0));
    selv[0].disabled = true;
  } else {
    let frag = document.createDocumentFragment(), elOption;
    elOption = frag.appendChild(new Option('', 0));
    let vss = vsArray[b][c];
    for (let i = 1; i <= vss; i++) {
      elOption = frag.appendChild(new Option(i));
    }
    selv.appendChild(frag);
    if (v <= vss) {
      selv.value = v;
    }
  }
}

/* Toggle shortcut buttons off, and sort by hits option off, if Exact Phrase is selected */
/* However, hits is still available if we are searching Headings */
window.addEventListener("load", toggleExactPhrase);
document.getElementById("adv_search_match_exact").addEventListener("change", toggleExactPhrase);
document.getElementById("adv_search_scopeboth").addEventListener("change", toggleExactPhrase);
document.getElementById("adv_search_scopeheading").addEventListener("change", toggleExactPhrase);

function toggleExactPhrase() {
  let exactChecked = document.getElementById("adv_search_match_exact").checked;
  let headingsChecked = document.getElementById("adv_search_scopeheading").checked;
  let sortHits = document.getElementById("adv_search_sort_hits");

  if (exactChecked) {
    document.querySelectorAll('.insert-buttons input').forEach(element => {
      element.disabled = true;
    });
    if (headingsChecked) {
      sortHits.disabled = false;
    } else {
      sortHits.disabled = true;
    }
  } else {
    document.querySelectorAll('.insert-buttons input').forEach(element => {
      element.disabled = false;
    });
    sortHits.disabled = false;
  }
}

/* Insert query shortcuts buttons */
/* Note: Manually add a space in the input's value in the html, for those where a space should be added */
document.querySelectorAll('.insert-buttons input').forEach(element => {
  let search_input = document.getElementById("adv_search_text");
  let symbol;
  element.addEventListener("click", function (event) {
    if (element.id == "button-caret") {
      symbol = " " + element.value;
    } else {
      symbol = " " + element.value + " ";
    }
    search_input.value = search_input.value + symbol;
    // Return focus to the input
    search_input.focus();
  });
  // Arrow navigation
  element.addEventListener("keydown", function (event) {
    if (event.keyCode == 37) {
      // Left arrow key pressed, select the previous button
      let prev = element.previousElementSibling;
      if (prev) {
        prev.focus();
      }
    } else if (event.keyCode == 39) {
      // Right arrow key pressed, select the next button
      let next = element.nextElementSibling;
      if (next) {
        next.focus();
      }
    }
  });
});

window.addEventListener("load", changeYrs("keep"));
document.getElementById("adv_search_pub").addEventListener("change", changeYrs);

function changeYrs(keep_change) {
  // Restrict years based on publication
  let pub_type = document.getElementById("adv_search_pub").value;
  let el_year_start = document.getElementById('adv_search_year_start');
  let orig_start = el_year_start.value;
  let el_year_end = document.getElementById('adv_search_year_end');
  let orig_end = el_year_end.value;

  let start = 1922;
  let end = 1997;

  if (pub_type === 'CWWN') {
    start = 1922;
    end = 1951;
  } else if (pub_type === 'CWWL') {
    start = 1932;
    end = 1997;
  } else if (pub_type === 'CNT') {
    start = 1985;
    end = 1986;
  } else if (pub_type === 'LS') {
    start = 1971;
    end = 1995;
  } else if (pub_type === 'HP') {
    start = 1991;
    end = 1994;
  }

  el_year_start.options.length = 0;
  let frag = document.createDocumentFragment(), elOption1;
  elOption1 = frag.appendChild(new Option('', ''));
  for (let i = start; i <= end; i++) {
    elOption1 = frag.appendChild(new Option(i));
  }
  el_year_start.appendChild(frag);
  // If 'keep', this was on window load and we need to maintain the previous selections
  if (keep_change === "keep") {
    el_year_start.value = orig_start;
  } else {
    el_year_start.value = start;
  }

  el_year_end.options.length = 0;
  let frag2 = document.createDocumentFragment(), elOption2;
  elOption2 = frag.appendChild(new Option('', ''));
  for (let i = start; i <= end; i++) {
    elOption2 = frag2.appendChild(new Option(i));
  }
  el_year_end.appendChild(frag2);
  // If 'keep', this was on window load and we need to maintain the previous selections
  if (keep_change === "keep") {
    el_year_end.value = orig_end;
  } else {
    el_year_end.value = end;
  }
}

document.getElementById("adv_search_pub").addEventListener("change", changeBookTitles);
document.getElementById("adv_search_year_start").addEventListener("change", changeBookTitles);
document.getElementById("adv_search_year_end").addEventListener("change", changeBookTitles);
window.addEventListener("load", changeBookTitles("preload"));

function changeBookTitles(preload) {
  let pub_type = document.getElementById("adv_search_pub").value;
  let el_year_start = document.getElementById('adv_search_year_start');
  let start = el_year_start.value;
  let el_year_end = document.getElementById('adv_search_year_end');
  let end = el_year_end.value;
  let el_book_titles = document.getElementById('adv_search_titles');

  // Create a separate array, as removing all the options will remove them from this pointer also
  let was_selected = [];
  let parsed_title;

  // If preload, this was on window load and we need to maintain the previous selections
  if (preload === "preload") {
    // This is a hidden form input
    let selected_book_titles = JSON.parse(document.getElementById('adv_search_titles_preload').value);
    for (let i = 0; i < selected_book_titles.length; i++) {
      parsed_title = selected_book_titles[i].replace(/&#34;/g, '"');
      parsed_title = parsed_title.replace(/&#39;/g, "'");
      was_selected.push(parsed_title);
    }
  } else {
    let selected_book_titles = el_book_titles.selectedOptions;
    for (let i = 0; i < selected_book_titles.length; i++) {
      was_selected.push(selected_book_titles[i].value);
    }
  }

  el_book_titles.options.length = 0;
  let disabled_option = new Option("Loading...", "");
  el_book_titles.add(disabled_option);
  disabled_option.disabled = true;

  fetch("/search/utils/search_ajax_book_titles.php?pubs=" + pub_type + "&year_start=" + start + "&year_end=" + end, {
    method: "GET",
  })
  .then(response => {
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Not authenticated. Please log in again.");
      } else {
        throw new Error("Failed");
      }
    }
    return response.text();
  })
  .then(data => {
    if (data !== 'Failure') {
      el_book_titles.options.length = 0;
//      console.log(data);
      const json_result = JSON.parse(data);
      for (let i = 0; i < json_result.length; i++) {
        let item = json_result[i];
//        console.log(i + ": title " + item.title);
        let book_option = new Option(item.title, item.sec_name);
        el_book_titles.add(book_option);
        book_option.title = item.title;
      }
      // Maintain items that were previously selected
      for (let i = 0; i < was_selected.length; i++) {
        let opt = document.querySelector("#adv_search_titles option[value='" + was_selected[i].replace(/'/g, "\\'") + "']");
        if (opt) {
          opt.selected = true;
        }
      }
      toggleAdvSearchClearTitles();
    } else {
      throw new Error("Failure");
    }
  })
  .catch(error => {
    el_book_titles.options.length = 0;
    let disabled_option = new Option("Sorry, there was an error.", "");
    console.error(error.stack);
    el_book_titles.add(disabled_option);
    disabled_option.disabled = true;
  });
}

document.getElementById("publication-filters-title").addEventListener("click", function (event) {
  togglePublicationFilters();
});

document.getElementById("toggle-publication-filters").addEventListener("click", function (event) {
  event.preventDefault();
  togglePublicationFilters();
});

function togglePublicationFilters() {
  let toggle_pub_filters = document.getElementById("toggle-publication-filters");
  let pub_filters = document.getElementById("publication-filters");
  if (pub_filters.classList.contains("hide")) {
    toggle_pub_filters.classList.add("hide_icon");
    toggle_pub_filters.classList.remove("show_icon");
    toggle_pub_filters.innerText = "Hide Filters";
    pub_filters.classList.remove("hide");
    pub_filters.style.display = "flex";
  } else {
    toggle_pub_filters.classList.remove("hide_icon");
    toggle_pub_filters.classList.add("show_icon");
    toggle_pub_filters.innerText = "Show Filters";
    pub_filters.classList.add("hide");
    pub_filters.style.display = "none";
  }
}

document.getElementById("adv_search_clear_titles").addEventListener("click", function (event) {
  event.preventDefault();
  document.getElementById("adv_search_titles").selectedIndex = -1;
  toggleAdvSearchClearTitles();
});

window.addEventListener("load", toggleAdvSearchClearTitles);
document.getElementById('adv_search_titles').addEventListener('change', toggleAdvSearchClearTitles);

function toggleAdvSearchClearTitles() {
  let sel = document.getElementById('adv_search_titles');
  let checked = [...sel.options].filter(option => option.selected).map(o => o.value);
  if (checked.length > 0) {
    document.getElementById("adv_search_clear_titles").style.display = "inherit";
  } else {
    document.getElementById("adv_search_clear_titles").style.display = "none";
  }
}

document.getElementById("adv_search_clear_scripture").addEventListener("click", function (event) {
  event.preventDefault();
  document.getElementById("adv_search_bk").selectedIndex = 0;
  changeCh();
  document.getElementById("adv_search_clear_scripture").style.display = "none";
});

window.addEventListener("load", toggleAdvSearchClearScripture);
document.getElementById('adv_search_bk').addEventListener('change', toggleAdvSearchClearScripture);

function toggleAdvSearchClearScripture() {
  if (document.getElementById("adv_search_bk").selectedIndex > 0) {
    document.getElementById("adv_search_clear_scripture").style.display = "inherit";
  } else {
    document.getElementById("adv_search_clear_scripture").style.display = "none";
  }
}

/* Display spinner when submit button pushed */
document.getElementById("adv_search_submit").addEventListener("click", function () {
  document.getElementById("searchSpinner").style.display = "inherit";
});

document.getElementById("adv_search_clear").addEventListener("click", function (event) {
  event.preventDefault();
  // Can't just reset or reload, as we may need to clear the values sent from the Edit Search button
  window.location.href = window.location.href.split(/[?#]/)[0];
});
